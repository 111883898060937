import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./superadmin/components/loader/Loader";
import Scrolltop from "./superadmin/components/ScrollToTop";
import "./App.css";

import PrivateRoute from "./auth/PrivateRoute";
import NotFound from "./superadmin/components/loader/NotFound";
const LoginWithPassword = lazy(() => import("./auth/LoginWithPassword"));
const AdminDashboard = lazy(() =>
  import("./superadmin/components/pages/Dashboards/AdminDashbaord")
);

const DoctorManagement = lazy(() =>
  import("./superadmin/components/pages/AllDocotorList")
);

const DocRegister = lazy(() =>
  import("./superadmin/components/pages/DoctorRegistrationForm")
);

const EditPatientByClinic = lazy(() =>
  import("./superadmin/components/pages/EditPatient")
);
const EditDoctorByClinic = lazy(() =>
  import("./superadmin/components/pages/EditDoctorProfilePoly")
);
const AllClinic = lazy(() =>
  import("./superadmin/components/pages/AllFacility")
);
const AllPatientsLIst = lazy(() =>
  import("./superadmin/components/pages/AllPatientsList")
);
const Dashboard = lazy(() =>
  import("./superadmin/components/layout/Dashboard")
);
const Adminuser = lazy(() => import("./superadmin/components/pages/AdminsAndSupport/AdminUser"));

const AllClinicStaff = lazy(() =>
  import("./superadmin/components/pages/AllFacilityStaff")
);
const AddClinicStaff = lazy(() =>
  import("./superadmin/components/pages/FacilityStaffRegistration")
);
const EditStaff = lazy(() =>
  import("./superadmin/components/pages/EditFacilityStaff")
);


const AddClinic2 = lazy(() =>
  import("./superadmin/components/pages/AddFacility2")
);
const EditClinic2 = lazy(() =>
  import("./superadmin/components/pages/EditFacility2")
);

const AllLabtest = lazy(() =>
  import("./superadmin/components/pages/AllLabtest")
);
const AllSymptoms = lazy(() =>
  import("./superadmin/components/pages/NewData/NewData")
);
const UploadRecord = lazy(()=>import("./superadmin/components/pages/Records/UploadRecord"));
const RegisterAdmin = lazy(()=>import("./superadmin/components/pages/AdminsAndSupport/RegisterAdmin"));
const Supportteam  = lazy (()=> import ("./superadmin/components/pages/AdminsAndSupport/SupportTeam"))
const ViewRecord =lazy(()=> import("./superadmin/components/pages/Records/ViewRecord"));
const AddFamilyMember = lazy(()=> import("./superadmin/components/pages/AddFamliy"));
const ContactUs = lazy(()=> import("./superadmin/components/pages/ContactUs"));
const HelpAndSupportTabel = lazy(()=> import("./superadmin/components/HelpandSupportTabel"));



const DemoPatient3 = lazy(()=> import ("./superadmin/components/pages/SearchAndAddPatient copy 3"));
 const MedicineScheduler  = lazy(()=> import ("./superadmin/components/pages/PillScheduler/MedicineReminderTab"));
function App() {
  return (
    <BrowserRouter>
      <Scrolltop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<LoginWithPassword />} />
          <Route path="*" element={<NotFound />} />

          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="doctormanagement" element={<DoctorManagement />} />
              <Route path="docregister" element={<DocRegister />} />
              <Route path="editpatientbyclinic" element={<EditPatientByClinic />} />
              <Route path="editdoctorbyclinic" element={<EditDoctorByClinic />} />
              <Route path="allclinic" element={<AllClinic />} />
              <Route path="addclinic" element={<AddClinic2 />} />
              <Route path="editclinic" element={<EditClinic2 />} />
              <Route path="allpatients" element={<AllPatientsLIst />} />
              <Route path="adminuser" element={<Adminuser />} />
              <Route path="allclinicstaff" element={<AllClinicStaff />} />
              <Route path="addclinicstaff" element={<AddClinicStaff />} />
              <Route path="editstaff" element={<EditStaff />} />
              <Route path="registeradmin" element={<RegisterAdmin />} />
              <Route path="registersupport" element={<Supportteam />} />
              <Route path="alllabtest" element={<AllLabtest />} />
              <Route path="allsymptoms" element={<AllSymptoms />} />
              <Route path="uploadrecord" element={<UploadRecord />} />
              <Route path="viewrecord" element={<ViewRecord />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route path="addfamily" element={<AddFamilyMember />} />
              <Route path="helpandsupport" element={<HelpAndSupportTabel />} />
              <Route path="addnewpatientpoly" element={<DemoPatient3 />} />
              <Route path="medicinescheduler" element={<MedicineScheduler />} />
              
            </Route>
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/support" element={<Dashboard />}>
              <Route path="dashboard" element={<AdminDashboard />} />
            
              <Route path="editpatientbyclinic" element={<EditPatientByClinic />} />
              <Route path="allpatients" element={<AllPatientsLIst />} />
              <Route path="adminuser" element={<Adminuser />} />
              <Route path="alllabtest" element={<AllLabtest />} />
              <Route path="allsymptoms" element={<AllSymptoms />} />
              <Route path="uploadrecord" element={<UploadRecord />} />
              <Route path="viewrecord" element={<ViewRecord />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route path="addfamily" element={<AddFamilyMember />} />
              <Route path="helpandsupport" element={<HelpAndSupportTabel />} />
              <Route path="addnewpatientpoly" element={<DemoPatient3 />} />

            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
