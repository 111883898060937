import React from 'react'

const NotFound = () => {
  return (
    <div className='container d-flex justify-content-center align-items-center h-100'>
        <div style={{minHeight:"100vh", minWidth:"100vw"}} className="d-flex flex-column justify-content-center align-items-center">
    
       <h1> 404 Error ! Page Not Found</h1>

        </div>

    </div>
  )
}

export default NotFound
