
import axios from 'axios';
import Swal from 'sweetalert2';

// Create an instance of axios
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_EHR, // Base URL for your API
  timeout: 10000, // Timeout in milliseconds
});

// Create an instance of axios for another base URL (e.g., file uploads)
const apiClientDoctor = axios.create({
  baseURL: process.env.REACT_APP_DOCTORURL, // Base URL for file upload APIs
  timeout: 10000, // Timeout in milliseconds
});
// Create an instance of axios for another base URL (e.g., file uploads)
const apiClientMedicineScheduler = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_REMINDER, // Base URL for file upload APIs
  timeout: 10000, // Timeout in milliseconds
});


// Common Request Interceptor for both instances
const requestInterceptor = (config) => {
  const token = sessionStorage.getItem('token'); // Get the token from localStorage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};


apiClient.interceptors.request.use(requestInterceptor, (error) => {
  Swal.fire({
    icon: 'error',
    title: 'Request Error',
    text: 'An error occurred while sending the request. Please try again.',
  });
  return Promise.reject(error);
});

apiClientDoctor.interceptors.request.use(requestInterceptor, (error) => {
  Swal.fire({
    icon: 'error',
    title: 'Request Error',
    text: 'An error occurred while sending the request. Please try again.',
  });
  return Promise.reject(error);
});
apiClientMedicineScheduler.interceptors.request.use(requestInterceptor, (error) => {
  Swal.fire({
    icon: 'error',
    title: 'Request Error',
    text: 'An error occurred while sending the request. Please try again.',
  });
  return Promise.reject(error);
});

// Response Interceptor for both instances
const responseInterceptor = (response) => {
  return response;
};

const errorInterceptor = (error) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      Swal.fire({
        icon: 'warning',
        title: 'Unauthorized',
        text: 'You are not authorized. Please log in again.',
      });
    } else if (status === 404) {
      Swal.fire({
        icon: 'error',
        title: 'Not Found',
        text: 'The requested resource was not found.',
      });
    } else if (status === 500) {
      Swal.fire({
        icon: 'error',
        title: 'Server Error',
        text: 'An internal server error occurred. Please try again later.',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.response?.message || 'An unexpected error occurred. Please try again.',
      });
    }
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Network Error',
      text: 'A network error occurred. Please check your connection.',
    });
  }
  return Promise.reject(error);
};

apiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
apiClientDoctor.interceptors.response.use(responseInterceptor, errorInterceptor);
apiClientMedicineScheduler.interceptors.response.use(responseInterceptor, errorInterceptor);
export { apiClient, apiClientDoctor, apiClientMedicineScheduler };
