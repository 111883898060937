// apiService.js
import { apiClientDoctor, apiClient ,apiClientMedicineScheduler} from "./apiClient";
// Example API functions
const apiService = {
  registerAdmin: (data) => apiClient.post("/superadminregister", data),
  //  Admin Login
  adminLogin: (data) => apiClient.post("/superadminlogin", data),
  userLogin: (data) => apiClient.post("/user-login", data),
  getuserInfo :()=> apiClient.get("/userInfoByToken"),
  logout :()=> apiClient.post("/user-logout"),
  //  Register Facility
  registerFacility: (facilityData) =>
    apiClient.post("/createFacility", facilityData, {
      Headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  // get All facility
  getAllFacilities: () => apiClient.post("/getAllFacility"),
  // get update facility
  updateFacility: (data) => apiClient.post("/updateFacility", data),
  // get All Speciality
  getAllSpeciality: () => apiClient.get("/getSpecialist"),
  //   Verify Phone Number
  sendOtpVerifyNumber: (data) =>
    apiClient.post(`/validate-register-mobile`, data),
  //  verify otp
  verifyOtp: (otpData) => apiClient.post(`/validate-otp`, otpData),
  //  Get Parent Facility
  getParentsFacilities: () => apiClient.post("/getAllFacility"),

  //  Register Patient
  registerPatient: (data) =>
    apiClient.post("/registerPatient", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  Get All Patients
  getAllPatients: () => apiClient.get("/getAllPatients"),

  //  update Patient
  updatePatient: (data) =>
    apiClient.post("/updatePatient", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  //  Get Doctor List
  getDoctorList: (data) => apiClient.post("/getDoctorList", data),
  //  Register Doctor
  registerDoctor: (data) => apiClient.post("/registerDoctor", data),
  updateDoctor: (data) => apiClient.post("/updateDoctor", data),

  //  Get All Facility Staff
  getAllFacilityStaff: (data) => apiClient.post(`/getAllFacilityStaff`, data),
  //  Register Facility Staff
  registerFacilityStaff: (staffData) =>
    apiClient.post(`/addFacilityStaff`, staffData, {
      Headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  update Facility Staff
  updateFacilityStaff: (staffData) =>
    apiClient.post(`/updateFacilityStaff`, staffData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  serachDoctor :(data)=> apiClient.post("/searchDoctorByNameOrMobileNo",data),
  //  Get All Specialization
  getAllSpecializations: () => apiClient.post(`/getAllSpecializations`),
  createRecord: (data) => apiClient.post("/createRecordWeb", data),
  getContactUs :(data)=> apiClient.post("/get-contact-us",data),
  updateContactUs :(data)=> apiClient.post("/update-contact-us",data),
  getHelpAndSupport :(data)=> apiClient.post("/get-help-support",data),
  updateHelpAndSupport:(data)=> apiClient.post("/update-help-support",data),

  searchPatient: (data) =>
    apiClientDoctor.post("/api/v1/patient/searchPatient", data),
  newDiagnosis: () => apiClient.get("/superadmDiagnosis"),
  newMedicine: () => apiClient.get("/superadmMedicines"),
  newClinicalFindings: () => apiClient.get("/superadmClinicFindings"),
  // newMedicine: () => apiClient.get("/superadmMedicine"),
  newSymptom: () => apiClient.get("/superadmSymptoms"),
  newLabtest: () => apiClient.get("/superadmLabTest"),
  approveLabtest: (data) =>
    apiClient.post("/superadmUpdateLabTestStatus", data),
  ApproveAndDeletedLabtestBulk: (data) =>
    apiClient.post("/superadmUpdateBulkLabTestStatus", data),

  approveDeleteEditSymptoms: (data) =>
    apiClient.post("/superadmUpdateSymptomsStatus", data),
  approveAndDeleteBulkSymptoms: (data) =>
    apiClient.post("/superadmUpdateBulkSymptomsStatus", data),

  approveDeleteEditDiagnosis: (data) =>
    apiClient.post("/superadmUpdateDiagnosisStatus", data),
  approveAndDeleteBulkDiagnosis: (data) =>
    apiClient.post("/superadmUpdateBulkDiagnosisStatus", data),

  approveDeleteEditClinicalFindings: (data) =>
    apiClient.post("/superadmUpdateClinicFindingsStatus", data),
  approveAndDeleteBulkClinicalFindings: (data) =>
    apiClient.post("/superadmUpdateBulkClinicFindingsStatus", data),
  getHealthRecord: (data) => apiClient.post("/getHealthRecords", data),
  getTestResults: (data) => apiClient.post("/getTestResults", data),
  getExpenses: (data) => apiClient.post("/getExpenses", data),
  admindashData:()=> apiClient.get("/superadminDashboard"),
  addFamilyMember: (data) => apiClient.post("/addFamily", data),
  deleteFamilyMember: (data) => apiClient.post("/deleteFamilyMember", data),
  getFamilyMember: (data) => apiClient.post("/getmyFammembers", data),

  approveDeleteEditMedicine: (data) => apiClient.post("/superadmUpdateMedicineStatus", data),
  approveAndDeleteBulkMedicine: (data) => apiClient.post("/superadmUpdateBulkMedicineStatus", data),
  allLabtest :()=> apiClientDoctor.get("/api/v1/labtest/tests"),

  createSchedule:(data)=> apiClientMedicineScheduler.post("/api/v1/medscheduler/createMedicineScheduleNew",data),
  getScheduleByPatientId:(data)=> apiClientMedicineScheduler.post("/api/v1/medscheduler/getMedicineSchedule",data),
  getOccurenace :()=> apiClientMedicineScheduler.get("/api/v1/occurence/getOccurence"),
  fetchMedicineScheduleByAction  :(data)=> apiClientMedicineScheduler.post("/api/v1/medscheduler/getMedicinesWithActionByDateNew",data),
  takeMedicine : (data)=> apiClientMedicineScheduler.post("/api/v1/medscheduler/updateScheduleNotificationNew",data),
  deleteSchedule : (data)=> apiClientMedicineScheduler.post("/api/v1/medscheduler/deleteMyMedicineScheduleNew",data),
  getScheduleByDateGroup:(data)=> apiClientMedicineScheduler.post("/api/v1/medscheduler/getMedicineScheduleByDateGroupByFrequency",data),
  getfrequency :()=> apiClientDoctor.get("/api/v1/frequency/getFrequency"),
  getAllTest :()=> apiClientDoctor.get("/api/v1/labtest/tests"),
  getAllSymptoms :()=> apiClientDoctor.post("/api/v1/symptoms/searchsymtoms"),
  searchMedicine :(data)=> apiClientDoctor.post("/api/v1/medicine/searchMedicine", data),
  
};

export default apiService;
