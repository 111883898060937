import { createContext, useContext, useState } from "react";

export const AllFacilityData = createContext();

export const AllFacilityDataProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [licenses, setLicenses] = useState([]);
  return (
    <AllFacilityData.Provider
      value={{ searchQuery, setSearchQuery, licenses, setLicenses }}
    >
      {children}
    </AllFacilityData.Provider>
  );
};

export const useAllFacilityData = () => {
  return useContext(AllFacilityData);
};
