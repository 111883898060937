import { createContext, useContext, useState, useMemo } from "react";

const SearchDoctorDataContext = createContext();

export const SearchDoctorDataProvider = ({ children }) => {
  const [showform, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [searchFormData, setSearchFormData] = useState("");
  const [doctorList, setDoctorList] = useState([]);

  const value = useMemo(() => ({
    showform,
    setShowForm,
    showTable,
    setShowTable,
    searchFormData,
    setSearchFormData,
    doctorList,
    setDoctorList
  }), [showform, showTable, searchFormData, doctorList]);

  return (
    <SearchDoctorDataContext.Provider value={value}>
      {children}
    </SearchDoctorDataContext.Provider>
  );
};

export const useSearchDoctorData = () => useContext(SearchDoctorDataContext);